import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import Hero from '../components/shared/Hero'
import ContentBlock from '../components/shared/sub/ContentBlock'
import OurProgramsBlock from '../components/shared/OurProgramsBlock'
import Testimonials from '../components/shared/Testimonials'
import DonateBlock from '../components/shared/DonateBlock'
import SEO from '../components/shared/Seo'


function programs() {

  const { sanityProgramsPage } = useStaticQuery(graphql`
 {
  sanityProgramsPage {
    programsHeroBlock {
      heroText
      heroImage {
        asset {
          fluid {
            srcWebp
            srcSetWebp
            srcSet
            src
          }
          title
        }
      }
    }
    titleBlock {
      _rawContent
    }
    seoBlock {
          pageDescription
          pageKeyWords  
          pageTitle
          ogImage {
            asset {
              url
            }
          }
    }
  }
}

  `)

  const { programsHeroBlock, titleBlock } = sanityProgramsPage
  const { heroText, heroImage } = programsHeroBlock
  const { contentHeader, contentSubHeader, contentText } = titleBlock

  return (
    <div>
      <SEO seoData={sanityProgramsPage?.seoBlock} slug='programs' />
      <Hero heroText={heroText} heroImage={heroImage} blockVisible />
      <ContentBlock
        blocks={titleBlock._rawContent}
      />
      <OurProgramsBlock columnLayout headerHidden />
      <Testimonials width="auto" margin="20px 30px" />
      <DonateBlock />
    </div>
  )
}

export default programs
